import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { initCommerceApiWithGlobals } from 'common-api';
import { effectConsoleLogAtomUpdates } from '../helpers/debug';
import type { VineCartProps, CartItemProps, ErrorResponse } from 'tsconfig/types.d';
import { notificationsAtom } from './notificationsAtom';
import { getCurrentCartIdFromCookie, isBrowser } from '../helpers/utils';
import { logger, globals, GENERIC_ERROR_MESSAGE } from 'common-ui';
import { authAtom, getAnonymousToken, refreshAuthToken, reloadAuthToken } from './authAtom';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { selectUserDetails } from './userAtom';
import useWaitForCartToLoad from '../hooks/useWaitForCartToLoad';
import { cartNotification } from '../helpers/cartNotification';
import { getProducts } from '../helpers/getProducts';
import { commonHandlerForWebApiRequestError } from '../helpers/commonHandlerForWebApiRequestError';
import { handlePromoCodeValidation } from '../helpers/handlePromoCodeValidation';

export const commerceApiExtraConfig = (publicCsrfValue = ''): { webApiExtraHeaders: { [key: string]: string } } => ({
  webApiExtraHeaders: {
    [globals.authAndWebApiCsrfHeaderName as string]: publicCsrfValue,
  },
});

//Atom
//----------------------------------------------------------------------
export const cartAtom = atom<VineCartProps>({
  key: 'cartAtom', // unique ID (with respect to other atoms/selectors)
  default: {
    cartIsLoaded: false,
    cartItems: undefined,
    cartError: {
      hasCartError: false,
      cartErrorMessage: '',
    },
  }, // default value (aka initial value)
  effects: [effectConsoleLogAtomUpdates],
});

//Selectors
//----------------------------------------------------------------------
export const selectCartDetails = selector({
  key: 'selectCartDetails', // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const { cartItems }: VineCartProps = get(cartAtom);

    if (!cartItems?.totalLineItems) {
      return {
        itemsCount: 0,
        cartTotal: 0,
        hasQuantity: false,
      };
    }

    let cartTotal = cartItems.totalPrice.amount ?? 0;

    // Adjust cart total if using new AWS cart API and shipping information is present
    if (globals.useNewCartAPI) {
      const shippingPrice = cartItems?.shippingInformation?.price?.amount ?? 0;
      cartTotal = Math.max(cartTotal - shippingPrice, 0);
    }

    return {
      itemsCount: cartItems.totalLineItems,
      cartTotal,
      hasQuantity: cartItems.totalLineItems > 0,
    };
  },
});

/**
 * Cart data selector.
 *
 * This selector retrieves the cart data from the Recoil state.
 *
 * - key: 'cartData'
 * - get: Function - Fetches cart data from 'cartAtom' atom.
 */
export const selectCartData = selector({
  key: 'selectCartData',
  get: ({ get }) => {
    try {
      return get(cartAtom);
    } catch (error) {
      throw new Error(GENERIC_ERROR_MESSAGE);
    }
  },
});

//Hooks
//----------------------------------------------------------------------
export const useInitCartState = () => {
  const initCartStateWithAtgLegacyApi = useInitCartStateWithAtgLegacyApi();
  const initCartStateWithWebApi = useInitCartStateWithWebApi();

  return async (): Promise<void> => {
    if (isBrowser()) {
      if (globals.useNewCartAPI) {
        return initCartStateWithWebApi();
      }
      return initCartStateWithAtgLegacyApi();
    }
    logger.log('useInitCartState skipping fetch as not in browser');
  };
};

export function useInitCartStateWithAtgLegacyApi() {
  const { cartItems } = useRecoilValue(cartAtom);

  return async () => {
    if (cartItems) {
      logger.log('skipping init fetch of cart data as already defined');
      return;
    }
    await fetchCart();
  };
}

export function useInitCartStateWithWebApi() {
  const { cartItems } = useRecoilValue(cartAtom);

  return async () => {
    if (!isBrowser()) {
      logger.log('useInitCartStateWithWebApi skipping fetch as not in browser');
      return;
    }

    if (cartItems) {
      logger.log('skipping init fetch of cart data as already defined');
      return;
    }

    try {
      const { cartId } = getCurrentCartIdFromCookie();
      const authAtomState = getRecoil(authAtom);
      const cartHandler = cartId ? handleExistingCartId : handleNewCartId;
      await cartHandler(authAtomState);
    } catch (error: unknown) {
      const apiError = error as ErrorResponse;
      setRecoil(cartAtom, prevState => ({
        ...prevState,
        cartIsLoaded: true,
        cartError: {
          hasCartError: true,
          cartErrorMessage: apiError?.message || GENERIC_ERROR_MESSAGE,
        },
      }));
      logger.error('Error during cart state initialization:', error);
    }
  };
}

async function handleExistingCartId(authAtomState: any) {
  try {
    if (!authAtomState?.publicCsrf) {
      await reloadAuthToken();
    }

    await fetchCart();
  } catch (error) {
    logger.error('Error handling existing cart ID:', error);
    throw error;
  }
}

async function handleNewCartId(authAtomState: any) {
  try {
    const { loginType } = getRecoil(selectUserDetails);

    if (loginType === 'unidentified') {
      await getAnonymousToken();
    } else {
      if (!authAtomState?.publicCsrf) {
        await reloadAuthToken();
      }
      await refreshAuthToken();
    }

    await createNewCart();
  } catch (error) {
    logger.error('Error handling new cart ID:', error);
    throw error; // Ensure the error is thrown to be caught by the caller
  }
}

// TODO: Move all addToCart hooks to a separate file
// Add to cart action starts here
export function useActionAddToCart() {
  const addToCartWithAtgLegacyApi = useAddToCartWithAtgLegacyApi();
  const addToCartWithWebApi = useAddToCartWithWebApi();
  const isNewCartAPI = globals?.useNewCartAPI;

  return async (items: CartItemProps[]): Promise<void> => {
    if (isNewCartAPI) {
      return addToCartWithWebApi(items);
    }
    const [{ itemCode, quantity }] = items; // Destructure the first item directly
    return addToCartWithAtgLegacyApi(itemCode, quantity.toString());
  };
}

export function useAddToCartWithAtgLegacyApi() {
  const [, setCartData] = useRecoilState(cartAtom);
  const setNotification = useSetRecoilState(notificationsAtom);

  return async (productSku: string, quantity: string): Promise<void> => {
    try {
      const commerceApi = initCommerceApiWithGlobals();
      const newCartData = await commerceApi.Cart.add(productSku, quantity);
      setCartData(prevState => ({
        ...prevState,
        cartIsLoaded: true,
        cartItems: {
          ...prevState.cartItems,
          ...newCartData,
        },
      }));
      const notification = cartNotification(productSku, quantity);
      setNotification([notification]);
    } catch (e) {
      logger.error('API ERROR', e);
      throw e;
    }
  };
}

export function useAddToCartWithWebApi(): (items: CartItemProps[]) => Promise<void> {
  const [, setCartData] = useRecoilState(cartAtom);
  const setNotification = useSetRecoilState(notificationsAtom);
  const waitForCartToLoad = useWaitForCartToLoad();

  return async (cartItems: CartItemProps[]) => {
    async function doAddToCart() {
      if (!isBrowser()) {
        logger.log('useAddToCartWithWebApi skipping as not in browser');
        return;
      }
      const cartData = getRecoil(cartAtom);

      // If cartData is not available, throw Error
      if (!cartData) {
        throw new Error(GENERIC_ERROR_MESSAGE);
      }

      let authAtomState = getRecoil(authAtom);

      // If public CSRF is not available in state, then fetch from /pagereload endpoint
      if (!authAtomState?.publicCsrf) {
        authAtomState = (await reloadAuthToken()) || authAtomState;
      }

      const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));
      const { cartId, cartVersion } = getCurrentCartIdFromCookie();
      const items = cartItems.map(({ itemCode, quantity }) => ({
        itemCode,
        quantity: Number(quantity),
      }));

      const newCartData = await commerceApi.CartPreferences.addItemToCart({
        cartId,
        data: { version: cartVersion, items },
      });

      const productArray = await getProducts(newCartData);

      // Batch update cart data
      setCartData(prevState => ({
        ...prevState,
        cartIsLoaded: true,
        cartItems: {
          ...prevState.cartItems,
          ...newCartData,
          ...(productArray?.length ? { lineItems: productArray } : {}),
        },
      }));

      const minicartPayload =
        cartItems.length > 1
          ? cartItems.find(item => item.upsellOfferDetails?.type.toLowerCase() !== 'wineplan')
          : cartItems[0];

      if (!minicartPayload) {
        logger.log('No valid item found for notification');
        return;
      }

      const { itemCode, quantity } = minicartPayload;
      const notification = cartNotification(itemCode, quantity.toString());
      setNotification([notification]);
    }

    try {
      await waitForCartToLoad();
      await doAddToCart();
    } catch (error: any) {
      logger.error('Error occurred while adding to cart:', error);
      return commonHandlerForWebApiRequestError(error, doAddToCart);
    }
  };
}

export function useActionBatchAddToCart() {
  const setCartData = useSetRecoilState(cartAtom);
  const [, setNotification] = useRecoilState(notificationsAtom);

  return async (cartItems: any) => {
    try {
      const commerceApi = initCommerceApiWithGlobals();
      const newCartData = await commerceApi.Cart.batch(cartItems);
      if (newCartData) {
        setCartData(prevState => ({
          ...prevState,
          cartIsLoaded: true,
          cartItems: {
            ...prevState.cartItems,
            ...newCartData,
          },
        }));
        const [{ itemCode, quantity }] = cartItems.cartItems.slice(1, 2);
        const notification = cartNotification(itemCode, quantity);
        setNotification([notification]);
      }
    } catch (error) {
      logger.error('API ERROR', error);
      throw error;
    }
  };
}

// Creates new Cart (only necessary in WebApi)
export async function createNewCart(): Promise<void> {
  async function doCreateCart(): Promise<void> {
    if (!isBrowser()) {
      logger.log('createNewCart skipping as not in browser');
      return;
    }

    let authAtomState = getRecoil(authAtom);

    // If public CSRF is not available in state, then fetch from /pagereload endpoint
    if (!authAtomState?.publicCsrf) {
      authAtomState = (await reloadAuthToken()) || authAtomState;
    }

    const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));

    const promoData = handlePromoCodeValidation();

    const newCartCreationData = await commerceApi.CartPreferences.createNewCart(promoData);
    setRecoil(cartAtom, prevState => ({
      ...prevState,
      cartIsLoaded: true,
      cartItems: {
        ...prevState.cartItems,
        ...newCartCreationData,
      },
    }));
  }

  try {
    await doCreateCart();
  } catch (e: any) {
    return commonHandlerForWebApiRequestError(e, doCreateCart);
  }
}

// Function to Fetch the latest cart data
export async function fetchCart(): Promise<void> {
  if (globals.useNewCartAPI) {
    return fetchCartWithWebApi();
  }
  return fetchCartWithAtgLegacyApi();
}

export async function fetchCartWithAtgLegacyApi(): Promise<void> {
  try {
    const commerceApi = initCommerceApiWithGlobals();
    const newCartData = await commerceApi.Cart.get();
    setRecoil(cartAtom, prevState => ({
      ...prevState,
      cartIsLoaded: true,
      cartItems: {
        ...prevState.cartItems,
        ...newCartData,
      },
    }));
  } catch (e) {
    logger.error('API ERROR', e);
  }
}

export async function fetchCartWithWebApi(): Promise<void> {
  async function doFetchCart() {
    if (!isBrowser()) {
      logger.log('fetchCartWithWebApi skipping as not in browser');
      return;
    }

    let authAtomState = getRecoil(authAtom);

    // If public CSRF is not available in state, then fetch from /pagereload endpoint
    if (!authAtomState?.publicCsrf) {
      authAtomState = (await reloadAuthToken()) || authAtomState;
    }

    const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));

    const { cartId } = getCurrentCartIdFromCookie();

    const newCartData = await commerceApi.CartPreferences.getMyCart({ cartId });

    handlePromoCodeValidation(newCartData);

    // Pass the Cart data to getProducts fn to fetch product details
    const productArray = await getProducts(newCartData);

    // Update the cart state with products (if available)
    setRecoil(cartAtom, prevState => ({
      ...prevState,
      cartIsLoaded: true,
      cartItems: {
        ...prevState.cartItems,
        ...newCartData,
        ...(productArray?.length ? { lineItems: productArray } : {}),
      },
    }));
  }

  try {
    await doFetchCart();
  } catch (e: any) {
    return commonHandlerForWebApiRequestError(e, doFetchCart);
  }
}
