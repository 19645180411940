import React, { useEffect, useState } from 'react';
import {
  Button,
  Icon,
  NEW_CART_PAGE,
  OLD_CART_PAGE,
  globals,
  useCheckoutRedirect,
  Link,
  smallProductImageUrl,
  ProductImage,
  handleDLMiniCartClick,
  BrandId,
  CardType,
  BottleCount,
} from 'common-ui';
import { productLinkBuilder } from 'common-next/src/components/common-functions';
import { formatCurrency } from 'common-ui/src/utils/formatCurrency';
import type { LineItem, MiniCartLayoutProps } from 'tsconfig/types';

export const getGiftAndDepositeCardImage = (brandId: string, cardType: string) => {
  const normalizedBrandId = brandId === BrandId.LAW ? BrandId.LW : brandId;
  const imageName = cardType === CardType.DEPOSIT ? 'initialdeposit' : 'giftcard';

  return `${globals.publicHost}/images/us/en/product/${normalizedBrandId}-${imageName}.png`;
};
export const checkIsUnlimitedItem = (lineItems: LineItem[]): boolean =>
  lineItems.some(({ sku, product }) => {
    const schemeId =
      sku?.schemeDetails?.[0]?.schemeId?.toLowerCase() || product?.schemeDetails?.schemeId?.toLowerCase();
    return schemeId ? /ul|unl|unt|unlt/.test(schemeId) : false;
  });

export const getDefaultImageSrc = (lineItems: LineItem[]): string =>
  `${globals.publicHost}${globals.defaultImagePath}/${
    lineItems[0]?.product?.productType === 'Case' ? 'default_case' : 'default_bottle'
  }.png`;

const MiniCartLayout: React.FC<MiniCartLayoutProps> = ({
  lineItems,
  totalLineItem,
  onClose,
  isFreeGiftLineItem,
  isGreatPick,
  qty,
  showCheckoutButton,
}) => {
  const redirectCheckoutRoute = useCheckoutRedirect();

  const [imageSrc, setImageSrc] = useState<string>(getDefaultImageSrc(lineItems));

  const productHref = productLinkBuilder(
    lineItems[0]?.sku?.productName[0]?.value,
    lineItems[0]?.product?.vintage || '',
    lineItems[0]?.sku?.itemCode,
  );

  useEffect(() => {
    // TODO: Need to update this once AWS cart API is implemented for US
    const getProductImageUrl = smallProductImageUrl;
    let newImageSrc = getProductImageUrl(lineItems[0]?.product?.itemCode ?? '');

    if (globals.country !== 'uk') {
      const productType = lineItems[0]?.product?.productType.toLowerCase();

      if (globals.country === 'au' && checkIsUnlimitedItem(lineItems)) {
        newImageSrc = `${globals.publicHost}/images/au/common/logos/unlimited_logo.svg`;
      } else if (productType === 'voucher') {
        newImageSrc = getGiftAndDepositeCardImage((globals?.brandId as string) ?? '', 'GIFT');
      } else if (productType === 'service' && lineItems[0]?.itemClassifications?.includes('DEPOSIT_ITEM')) {
        const depositInfo = lineItems[0]?.lineItemIdentification?.depositInformation;

        if (depositInfo?.depositType === 'PRESELL') {
          newImageSrc = getProductImageUrl(depositInfo?.associatedItemCode ?? '');
        } else {
          newImageSrc = getGiftAndDepositeCardImage((globals?.brandId as string) ?? '', 'DEPOSIT');
        }
      }
    }

    setImageSrc(newImageSrc);
  }, [lineItems]);

  useEffect(() => {
    handleDLMiniCartClick('launchMiniCart', 'launchMiniCart');
  }, []);

  const handleRedirectionClick = (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
    const currentUrl = window.location.pathname;
    if (currentUrl === href) {
      e.preventDefault();
    }
    onClose();
  };

  const handleMiniCartClickEvents = (clickEvent: string) => {
    clickEvent !== '' && handleDLMiniCartClick('miniCartClick', clickEvent);
  };

  const handleContinueShopping = () => {
    onClose();
  };

  const redirectLink = globals?.useNewCartAPI ? NEW_CART_PAGE : OLD_CART_PAGE;

  const VIEW_CART_TEXT = globals?.country !== 'uk' ? 'cart' : 'basket';
  const message = `You ${globals?.country === 'us' ? 'qualify for' : 'got'} a free gift`;

  const renderGift = (className: string) => (
    <div className={className}>
      <Icon kind="gift" size="s" />
      <h4>
        <span>{message}</span>
        <Link
          href={globals?.useNewCartAPI ? NEW_CART_PAGE : OLD_CART_PAGE}
          className="cart-link light-grey"
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            handleRedirectionClick(e, redirectLink);
            handleMiniCartClickEvents('viewInCarttMiniCartClick');
          }}
        >
          {`View in ${VIEW_CART_TEXT}`}
        </Link>
      </h4>
    </div>
  );

  const ViewCart = () => (
    <Button
      kind="secondary"
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
        handleRedirectionClick(e, redirectLink);
        handleMiniCartClickEvents('viewMiniCartClick');
      }}
      className="view-basket"
    >
      VIEW {VIEW_CART_TEXT.toUpperCase()} ({totalLineItem} ITEM{totalLineItem > 1 && 'S'})
    </Button>
  );

  const getStrikethroughPrice = (lineItem: LineItem, itemQty: number) => {
    const hasDiscountSavings = lineItem?.savings?.totalProductDiscountSavings > 0;
    const listPrice = lineItem?.unitPrice?.listPrice?.amount;
    const currentPrice = lineItem?.unitPrice?.value?.amount;

    if (hasDiscountSavings && listPrice * itemQty !== currentPrice) {
      return <h6 className="price strike">{formatCurrency(listPrice * itemQty)}</h6>;
    }

    return null;
  };

  //TODO : This country conditional should be handled in the BFF mapper.
  const itemQty = globals?.country !== 'uk' ? Number(lineItems[0]?.quantity) : Number(qty);

  return (
    <>
      <div className="wrapper">
        <div className="product-image">
          <ProductImage
            src={imageSrc}
            productType={lineItems[0]?.product?.productType}
            alt={lineItems[0]?.sku?.productName[0]?.value || ''}
            fill={true}
          />
        </div>
        <div className="product-details-container">
          <h5 className="product-great-pick lower">{isGreatPick ? 'Another Great Pick!' : 'Great Pick!'}</h5>
          <div className="product-detail-text">
            {lineItems[0]?.product?.productType?.toLowerCase() !== 'service' ? (
              <Link
                href={productHref}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleRedirectionClick(e, productHref)}
              >
                <h5 className="product-link lower">
                  <span className="checkout-item-qty">{itemQty}× </span>
                  {lineItems[0]?.sku?.productName[0]?.value} {lineItems[0]?.product?.vintage || ''}
                </h5>
              </Link>
            ) : (
              <h5 className="product-link lower">
                {lineItems[0]?.sku?.productName[0]?.value} {lineItems[0]?.product?.vintage || ''}
              </h5>
            )}
          </div>
          <BottleCount
            productType={
              lineItems[0]?.product?.skus?.[0]?.referencedSkus?.[0]?.expandedItem?.productType
                ? lineItems[0]?.product?.skus?.[0]?.referencedSkus?.[0]?.expandedItem?.productType
                : lineItems[0]?.product?.productType
            }
            bottleType={lineItems[0]?.product?.bottleType}
            numberOfBottles={lineItems[0]?.sku?.numberOfBottles}
          />
          <div className="price-section">
            {getStrikethroughPrice(lineItems[0], itemQty)}
            <h6
              className={`price sale-price ${lineItems[0]?.unitPrice?.priceType === 'MemberPrice Discount' ? 'member-price' : ''}`}
            >
              {globals?.country === 'uk'
                ? formatCurrency(lineItems[0]?.unitPrice?.value?.amount * itemQty)
                : formatCurrency(lineItems[0]?.unitPrice?.value?.amount)}
            </h6>
          </div>
          {globals?.country !== 'uk' && lineItems[0]?.unitPrice?.priceType === 'MemberPrice Discount' && (
            <p className="member-savings">
              {formatCurrency(lineItems[0]?.savings?.totalProductDiscountSavings)}{' '}
              {globals.brandId === 'wsj' ? 'ADVANTAGE' : 'UNLIMITED'} MEMBER SAVINGS
            </p>
          )}
          {isFreeGiftLineItem && renderGift('gift-tablet')}
        </div>
      </div>

      {isFreeGiftLineItem && renderGift('gift-other-device')}
      <div className="button-container">
        <Link href={globals?.useNewCartAPI ? NEW_CART_PAGE : OLD_CART_PAGE} prefetch={false}>
          <ViewCart />
        </Link>
        {showCheckoutButton && (
          <a href={redirectCheckoutRoute}>
            <Button
              kind="primary"
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                handleRedirectionClick(e, redirectCheckoutRoute);
                handleMiniCartClickEvents('checkoutMiniCartClick');
              }}
            >
              CHECKOUT
            </Button>
          </a>
        )}
      </div>

      <div
        className="continue-shopping mobile"
        onClick={() => {
          handleContinueShopping();
          handleMiniCartClickEvents('continueShoppingtMiniCartClick');
        }}
        tabIndex={0}
        role="button"
        onKeyDown={() => {
          handleContinueShopping();
          handleMiniCartClickEvents('continueShoppingtMiniCartClick');
        }}
      >
        <span className="continue-shopping-text">continue shopping</span>
      </div>
    </>
  );
};

export default MiniCartLayout;
